<template>
  <div class="modal" :class="{ 'is-active': showDatesModal }">
    <div class="modal-background" @click.self="close_modal"></div>
    <div class="modal-card" style="border: solid 1px #ffffff">
      <div class="has-background-white-ter p-2">
        <div class="is-flex">
          <div>
            <transition duration="1000" appear enter-active-class="animated flipInX"
              leave-active-class="animated flipOutX">
              <small v-if="$parent.currentDates.dates" class="tag is-medium is-info mr-2 mt-1">
                {{ $parent.currentDates.Qty }}
              </small>
            </transition>
          </div>
          <div class="is-flex-grow-1 no-line-height has-text-dark pt-1">
            <!-- <pre></pre> -->
            <transition delay="1000" appear enter-active-class="animated flipInX"
              leave-active-class="animated flipOutX">
              <!-- <div class="has-text-link "
                                v-if="$parent.currentWarehouse">
                                <b class="has-text-dark">
                                    {{$parent.currentWarehouse.Caption}} 
                                </b>
                            </div> -->
            </transition>
            <span v-if="$parent.currentDates.hash">
              {{ $parent.currentDates.warehouse.Caption }}
            </span>
            <small v-html="$parent.getWarehouse($parent.currentDates.warehouse_job)">
            </small>
            <!-- <br> -->
            <b class="is-block mt-1">
              {{ $parent.currentMaterial.Caption }}
            </b>
          </div>
        </div>
      </div>
      <div class="modal-card-body modal-bo">
        <div>
          <!-- {{ $parent.currentMaterial}} -->
          <label v-if="!$parent.currentDates.dates" class="label">
            <small>{{ $t("please_select_dates_below") }} </small>
          </label>
          <div class="columns mb-1 mt-1 mx-0 is-clickable" @click="
              $parent.getMaterialStock(d);
              getConnectableProjects(d);
            " v-for="(d, indexa) in $parent.currentMaterial.date_amount" :key="indexa"
            :class="{ 'is-tr-selected': $parent.currentDates == d }">
            <div class="column is-9 p-1" :class="{
                'has-background-info has-text-white': !d.added,
                'has-background-success-light has-background-light':
                  d.added == 1,
                'has-background-success-light has-background-light has-text-success-dark is-size-7':
                  d.added == 2 || d.added == 3,
                'is-light':
                  !$parent.currentDates.dates ||
                  ($parent.currentDates !== d && !d.added),
              }">
              <div v-if="d.added == 2">
                <span v-if="d.warehouse_job" class="px-1 is-flex is-pulled-left">
                  {{ $parent.getWarehouseNr(d.warehouse_job) }}
                </span>
                <br />
                <span v-if="d.warehouse_job" class="pl-1 pr-1 is-block is-flex is-align-items-center">
                  {{ $parent.getWarehouseCaption(d.warehouse_job) }}
                  <span class="is-size-6 is-block ml-2 has-text-success-dark" style="height: 100%">
                    {{ d.dates[0] | customdate }} -
                    {{ d.dates[1] | customdate }}
                  </span>
                </span>
              </div>
              <div v-else class="is-flex">
                <span class="is-size-6 is-block is-pulled-right"
                  :class="{ '': d.added != 2, 'has-text-dark': d.added == 1 }" style="height: 100%">
                  {{ d.dates[0] | customdate }} - {{ d.dates[1] | customdate }}
                </span>
              </div>
            </div>
            <div
              class="column is-2 p-1 has-text-centered has-text-white is-flex is-justify-content-center is-align-items-center"
              :class="{
                'has-background-light':
                  $parent.currentDates.dates &&
                  $parent.currentDates !== d &&
                  !d.added,
                'has-background-link': !d.added,
                'has-background-success is-light': d.added == 1,
                'has-background-success is-light  ':
                  d.added == 2 || d.added == 3,
              }">
              {{ d.Qty }}
              <i v-if="d.added == 2" class="fas fa-check ml-2"></i>
            </div>
            <div class="column is-1 p-1 has-text-centered" v-if="d.added == 2" @click.stop="delete_job(d)"
              style="height: 100%; vertical-align: middle; border: none">
              <!-- <i class="fa fa-trash has-text-danger-dark"></i> -->
            </div>
            <div class="column is-1 p-1 has-text-centered" v-if="d.added == 1">
              <i class="far fa-list-alt has-text-white"></i>
            </div>
          </div>
        </div>
        <hr class="my-3" />
        <div v-if="$parent.currentDates.dates && $parent.currentDates.added < 1" class="is-flex is-align-items-center">
          <label class="label">
            {{ $t("job_type") }}
          </label>
          <div class="job-type-wrap is-flex is-justify-content-space-around is-flex-grow-4">
            <div>
              <input type="radio" id="warehouse" :value="1" v-model="job_type" />
              <label for="warehouse" class="is-capitalized">{{
                $t("warehouse")
                }}</label>
            </div>
            <div>
              <input type="radio" id="venue" :value="2" v-model="job_type" />
              <label for="venue" class="is-capitalized">{{
                $t("venue")
                }}</label>
            </div>
            <div>
              <input type="radio" id="rental" :value="3" v-model="job_type" />
              <label for="rental">{{ $t("rental") }}</label>
            </div>
          </div>
        </div>
        <hr v-if="$parent.currentDates.dates && $parent.currentDates.added < 1" class="my-3" />
        <div v-if="
            job_type == 1 &&
            $parent.currentDates.dates &&
            $parent.currentDates.added < 1
          " class="mt-2 p-0 mb-3 columns">
          <!-- Select warehouse -->
          <div class="column">
            <label for="" class="label">{{ $t("select_warehouse") }}</label>
            <div class="control">
              <div class="select is-pulled-right ml-2 is-fullwidth is-link">
                <select v-model="curr_warehouse" @change="get_warehouse_stock()"
                  :disabled="!$parent.currentDates.dates">
                  <option value="0">-- Choose warehouse --</option>
                  <option v-for="(w, index) in $parent.$parent.warehouses" :key="index" :value="w.Id">
                    {{ w.Caption }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="
            job_type == 2 &&
            $parent.currentDates.dates &&
            $parent.currentDates.added < 1
          " class="mt-2 p-0 mb-3 columns">
          <!-- Select warehouse -->
          <div class="column">
            <label for="" class="label">{{ $t("select_venue") }}</label>
            <div class="control">
              <div class="select is-pulled-right ml-2 is-fullwidth is-link">
                {{current_venue}}
                <select v-model="current_venue" :disabled="!$parent.currentDates.dates">
                  <option value="none">-- Choose venue --</option>
                  <option v-for="(w, index) in connectable_projects" :key="index" :value="w.guid">
                    {{ w.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div v-if="
            stockout == true &&
            curr_warehouse &&
            $parent.currentDates.added < 1 &&
            job_type == 1
          " class="notification is-warning">
          <p>
            <i class="fas fa-exclamation-triangle mr-2"></i>{{ $t("stockout") }}
          </p>
        </div>
        <div v-if="
            curr_warehouse > 0 &&
            $parent.currentDates.added < 1 &&
            job_type == 1
          ">
          <label for="" class="label">{{ $t("quantity_info") }}</label>

          <!-- Table version -->
          <table class="table is-fullwidth has-text-white is-bordered ware-qty-table is-narrow">
            <thead>
              <tr>
                <th width="30%">
                  {{ $t("dates") }}
                </th>
                <th class="has-text-centered">
                  {{ $t("warehouse_quantity") }}
                </th>
                <th class="has-text-centered">
                  {{ $t("job_quantity") }}
                </th>
                <th class="has-text-centered">
                  {{ $t("remaining_quantity") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="a in $parent.availability" :key="a.CalcDay">
                <th>
                  {{ a.CalcDay | customdate }}
                </th>
                <th class="has-text-centered has-background-dark has-text-light"
                  :class="{ 'has-background-success-dark': a.Inventory > 0 }">
                  {{ a.Inventory }}
                </th>
                <th class="has-text-centered has-background-info-light">
                  {{ $parent.currentDates.Qty }}
                  <small class="has-text-grey" v-if="$parent.currentMaterial.linked">
                    ({{
                    Math.ceil(
                    parseFloat($parent.currentDates.Qty) /
                    parseFloat($parent.currentMaterial.linked.factor)
                    )
                    }})
                  </small>
                </th>
                <th class="has-text-centered has-background-success has-text-white" :class="{
                    'has-background-warning':
                      a.Inventory < $parent.currentDates.Qty,
                  }">
                  {{
                  a.Inventory -
                  ($parent.currentMaterial.linked
                  ? Math.ceil(
                  parseFloat($parent.currentDates.Qty) /
                  parseFloat($parent.currentMaterial.linked.factor)
                  )
                  : $parent.currentDates.Qty)
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-card-foot is-block p-2">
        <div v-if="show_confirm_btn" class="create-job-modal-footer">
          <div class="">
            <p class="has-text-centered">
              <label for="" class="label">
                {{ $t("amount") }}
                <span v-if="
                    $parent.currentDates.QtyWas != $parent.currentDates.Qty &&
                    job_type != 2
                  " class="has-text-weight-bold; has-text-warning">
                  {{ $parent.currentDates.QtyWas - $parent.currentDates.Qty }}
                  left
                </span>
                <span v-if="
                    job_type == 2 &&
                    connectable_projects.find((c) => c.guid == current_venue)
                  ">
                  {{
                  connectable_projects.find((c) => c.guid == current_venue)
                  .amount > $parent.currentDates.Qty
                  ? ""
                  : "Max available: " +
                  connectable_projects.find(
                  (c) => c.guid == current_venue
                  ).amount
                  }}
                </span>
              </label>
            </p>
          </div>
          <div>
            <!-- <input class="input" type="number" v-model="$parent.currentDates.Qty" :max="$parent.currentDates.QtyWas" min="1"> -->
            <input class="input has-text-dark has-text-centered has-background-white" type="number"
              v-model="$parent.currentDates.Qty" />
          </div>
          <div>
            <!-- <div v-if="(!$parent.currentDates.added || $parent.currentDates.added == 3)" -->
            <div class="button add-pending-list is-pulled-right is-uppercase is-size-7"
              :class="{ 'is-warning': stockout }" v-if="material_added" @click="addJob">
              <i v-if="stockout" class="fas fa-exclamation-triangle fa-sm mr-1"></i>
              <i v-else class="fa fa-download mr-2"></i>
              {{ $t("confirm_dates") }}
            </div>
          </div>
        </div>
        <div v-else class="create-job-modal-footer">
          <p v-if="$parent.currentDates.added == 1" class="has-text-right has-text-white mr-2">
            <i class="far fa-list-alt"></i>
            {{ $t("added_pending_job_list") }}
          </p>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" @click.prevent="close_modal" aria-label="close"></button>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["showDatesModal"],
  data() {
    return {
      curr_warehouse: 0,
      current_venue: "none",
      job_type: 1,
      connectable_projects: [],
      material_added: true,
      // selected_date:[]
    };
  },
  watch: {
    job_type() {
      if (this.job_type == 1) this.current_venue = "none";
      else if (this.job_type == 2) this.curr_warehouse = 0;
    },
  },
  created() {
    if (
      this.$parent.currentWarehouse &&
      parseInt(this.$parent.currentWarehouse) > 0
    ) {
      // console.log('true')
      this.curr_warehouse = this.$parent.currentWarehouse;

      this.$parent.$parent.getItemAvailability(
        this.$parent.currentMaterial,
        this.$parent.current_modal_dates,
        this.$parent.currentWarehouse
      );

      this.getConnectableProjects(this.$parent.current_modal_dates);
    }

    // console.log('$parent.currentMaterial', this.$parent.currentMaterial)
    // console.log('modal...')

    // Added test TEMP
    // this.$parent.
  },
  mounted() { },
  computed: {
    stockout() {
      let is_stockout = false;
      // console.log('this.$parent.availability', this.$parent.availability)
      // console.log('this.$parent.currentDates.Qty', this.$parent.currentDates.Qty , )
      // if(this.$parent.currentWarehouse){
      this.$parent.availability.map((a) => {
        if (a.Inventory < this.$parent.currentDates.Qty) {
          is_stockout = true;
        }
      });
      // }
      return is_stockout;
    },
    selected_dates() {
      return 1;
    },
    // curr_warehouse(){
    //     if(this.$parent.currentDates.warehouse_job){
    //         return this.$parent.currentDates.warehouse_job
    //     } else {
    //         return {}
    //     }
    // },
    show_confirm_btn() {
      var a = false;
      // console.log('this.$parent.currentWarehouse', this.$parent.currentWarehouse)
      if (
        this.$parent.currentDates.added < 1 &&
        (this.curr_warehouse != 0 || this.current_venue != "none")
      ) {
        a = true;
      }
      return a;
    },
  },
  methods: {
    getConnectableProjects(d) {
      this.curr_warehouse = 0;
      console.log(this.$parent.currentMaterial, "materiali");
      axios
        .post(this.$tetris_server + "/connectable/projects", {
          material_id: this.$parent.currentMaterial.IdST,
          linked_material:
            this.$parent.currentMaterial.linked === null
              ? ""
              : this.$parent.currentMaterial.linked.material_stocktype,
          project_id: this.$parent.$parent.currentEvent,
          material_date: d,
        })
        .then((resp) => {
          this.connectable_projects = resp.data;
        });
    },
    addJob() {
      if (
        this.job_type == 2 &&
        this.$parent.currentDates.Qty >
        this.connectable_projects.find((c) => c.guid == this.current_venue)
          .amount
      ) {
        alert("Amount not available!");
        return;
      }
      if (this.job_type == 2) {
        let a = this.current_venue;
        let eventname = this.connectable_projects.find(
          (p) => (p.guid == a)
        ).name;
        if (
          confirm(
            "Sure you want to get this amount from event " + eventname + "?"
          )
        ) {
          console.log(
            "set to material: ",
            this.$parent.current_modal_dates,
            " this event",
            this.current_venue
          );
          axios
            .post(this.$tetris_server + "/connect/material/to/project", {
              selected_amount: this.$parent.currentDates.Qty,
              total_amount: this.$parent.currentDates.QtyWas,
              remained_amount:
                this.$parent.currentDates.QtyWas -
                this.$parent.currentDates.Qty,
              material_id: this.$parent.current_modal_dates.m_guid,
              project_id: this.$parent.$parent.currentEvent,
              connected_project_id: this.current_venue,
              material_linked: this.$parent.current_modal_dates.m_linked,
            })
            .then((resp) => {
              if (resp.data == 1)
                this.$parent.$parent.jobs = [
                  ...this.$parent.$parent.jobs.map((j) => {
                    if (j.Caption.toLowerCase().indexOf("- request") > -1) {
                      j.materials = [
                        ...j.materials.map((m) => {
                          m.date_amount = [
                            ...m.date_amount.map((dm) => {
                              if (
                                dm.m_guid ==
                                this.$parent.current_modal_dates.m_guid
                              ) {
                                dm.m_connected_project_id = a;
                                dm.m_connected_project_name =
                                  this.connectable_projects.find(
                                    (p) => (p.guid = a)
                                  ).name;
                                dm.added = 3;
                              }
                              return dm;
                            }),
                          ];
                          return m;
                        }),
                      ];
                    }
                    return j;
                  }),
                ];

              this.current_venue = "none";
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            });
        }
      } else if (this.job_type == 1) {
        console.log(
          "set to material: ",
          this.$parent.current_modal_dates,
          this.curr_warehouse,
          this.$parent.currentMaterial
        );

        // search in the warehouse jobs if this material could be attached to one of them
        // also prevent if the current dates can be attached to multiple warehouse jobs... attach automatically to the first
        // having multiple warehousejobs for the same dates should be disabled at this point but the same can not be said
        // for concurrent users doing this, the check is against the list of jobs in tetris
        var once = 0;
        var attached_to_previous_job = false;
        this.$parent.$parent.jobs_warehouses.map((wj) => {
          if (once == 0)
            if (
              wj.details.DayTimeOut.replace("T", " ") ==
              this.$parent.current_modal_dates.dates_original[0] &&
              wj.details.DayTimeIn.replace("T", " ") ==
              this.$parent.current_modal_dates.dates_original[1] &&
              wj.Stock.ID == this.curr_warehouse
            ) {
              once = 1;
              console.log("wj", wj);
              var post_data = {
                IdJob: wj.ID,
                IdStockType: this.$parent.currentMaterial.IdST,
                Quantity: this.$parent.current_modal_dates.Qty,
              };
              attached_to_previous_job = true;
              axios
                .post(this.$ej_server + "/api.json/Items/Book", post_data, {
                  headers: this.$ej_headers,
                })
                .then((r) => {
                  // check if easyjob did the save the amount
                  if (r.data.Success) {
                    // check if the material is already in the list of the materials of this warehouse job
                    var already_in_list = false;
                    wj.details.materials.map((m) => {
                      m.date_amount.map((mm) => {
                        if (mm.IdST == this.$parent.currentMaterial.IdST) {
                          already_in_list = true;
                        }
                      });
                    });
                    // if no update the m_id with this warehouse job
                    if (!already_in_list) {
                      post_data.old_guid =
                        this.$parent.current_modal_dates.m_guid;
                      post_data.QtyWas =
                        this.$parent.current_modal_dates.QtyWas;
                      post_data.warehouse_job = wj.ID;
                      this.$parent.$parent.jobs_requests.map((j) => {
                        j.materials.map((m) => {
                          m.date_amount.map((dm) => {
                            if (
                              dm.warehouse_job == post_data.IdJob &&
                              m.IdST != this.$parent.currentMaterial.IdST
                            ) {
                              if (
                                this.$parent.current_modal_dates.Qty ==
                                this.$parent.current_modal_dates.QtyWas
                              ) {
                                this.$parent.current_modal_dates.added = 2;
                                this.$parent.current_modal_dates.warehouse_job =
                                  dm.warehouse_job;
                                // this.$parent.current_modal_dates.QtyWas
                                console.log(
                                  "new",
                                  dm,
                                  this.$parent.current_modal_dates
                                );
                              }
                              if (
                                this.$parent.current_modal_dates.Qty <
                                this.$parent.current_modal_dates.QtyWas
                              ) {
                                //TODO: Split the materials
                                // this.$parent.current_modal_dates.added = 2
                                // this.$parent.current_modal_dates.warehouse_job = dm.warehouse_job
                                console.log(
                                  "modal",
                                  this.$parent.current_modal_dates,
                                  dm
                                );
                              }
                            }
                          });
                        });
                      });
                      axios
                        .post(
                          this.$tetris_server +
                          "/create/existing/warehouse/job/material/amount",
                          post_data
                        )
                        .then((response) => {
                          console.log(response);
                          this.$toast("Material added to warehouse", "success");
                          this.material_added = false;
                        });
                    } else {
                      post_data.old_guid =
                        this.$parent.current_modal_dates.m_guid;
                      post_data.update_guid = "";
                      this.$parent.$parent.jobs_requests.map((j) => {
                        j.materials.map((m) => {
                          m.date_amount.map((dm) => {
                            if (
                              dm.warehouse_job == post_data.IdJob &&
                              m.IdST == this.$parent.currentMaterial.IdST
                            ) {
                              post_data.update_guid = dm.m_guid;
                              //update booked material qty
                              dm.Qty =
                                parseInt(dm.Qty) +
                                parseInt(this.$parent.current_modal_dates.Qty);
                              //update left material qty
                              this.$parent.current_modal_dates.Qty =
                                parseInt(
                                  this.$parent.current_modal_dates.QtyWas
                                ) -
                                parseInt(this.$parent.current_modal_dates.Qty);
                              console.log(
                                "dm",
                                dm,
                                this.$parent.current_modal_dates,
                                this.$parent.currentMaterial
                              );
                            }
                          });
                        });
                      });
                      post_data.QtyWas =
                        this.$parent.current_modal_dates.QtyWas;
                      axios
                        .post(
                          this.$tetris_server +
                          "/update/warehouse/job/material/amount",
                          post_data
                        )
                        .then((response) => {
                          this.$toast("Material added to warehouse", "success");
                          this.material_added = false;
                          console.log(response.status);
                        });
                    }
                  }
                });
            }
        });

        if (!attached_to_previous_job) {
          let w = this.$parent.$parent.warehouses.filter(
            (r) => r.Id == this.curr_warehouse
          );

          this.$parent.currentWarehouse = w.length > 0 ? w[0] : {}; //this.curr_warehouse
          this.$parent.addJobMaterial();
          this.curr_warehouse = 0;
        }
      }
    },
    get_warehouse_stock() {
      this.$parent.currentWarehouse = this.curr_warehouse;
      this.$parent.$parent.getItemAvailability(
        this.$parent.currentMaterial,
        this.$parent.current_modal_dates,
        this.$parent.currentWarehouse
      );
    },
    close_modal() {
      // this.$parent.currentWarehouse = {}
      this.curr_warehouse = {};
      this.$parent.showDatesModal = false;
      this.$parent.currentMaterial = {};
      this.$parent.currentDates = {};
      this.$parent.currentWarehouse = {};
      console.log(
        "this.$parent.currentWarehouse ",
        this.$parent.currentWarehouse
      );
    },
    delete_job(j) {
      console.log("Delete this: ", j);
    },
  },
  // d from props
};
</script>
<style scoped>
.modal-card {
  /* max-height: calc(100vh - 160px); */
  max-height: 95vh;
}

.job-type-wrap {
  display: flex;
  justify-content: space-around;
}

.job-type-wrap>* {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qty-info-head>span {
  /* border-bottom:solid 1px #000; */
}

.ware-qty-table tbody tr th {
  border-bottom: solid 1px #dbdbdb;
}

.ware-qty-table tbody tr th:not(:first-child) {
  color: #000;
}

.create-job-modal-footer {
  display: flex;
  align-items: center;
}

.create-job-modal-footer>* {
  flex-grow: 1;
  flex-basis: 0;
}

.added-border {
  border-bottom: 1px solid #d5d5d5;
}

.is-tr-selected {
  border: 1px solid #fff !important;
}

.add-pending-list {
  background: #639763 !important;
}
</style>
